@import 'helpers';

.cdk-global-overlay-wrapper {
  direction: inherit;
}

.mat-dialog {
  &-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.28;
    margin-bottom: 17px !important;

    @include breakpoint-max($tablet) {
      margin-bottom: 24px !important;
    }

    small {
      font-size: 16px;
      color: var(--color-neutral-70);
    }
  }

  &-actions {
    margin-top: 34px;
    padding-top: 0 !important;
    min-height: 40px !important;
    padding-bottom: 24px !important;

    @include breakpoint-max($tablet) {
      margin-top: 16px;
      min-height: 48px !important;
      margin-bottom: -16px !important;
      padding-bottom: 16px !important;
    }

    mzima-client-button {
      display: block;
      min-width: 124px;

      + mzima-client-button {
        @include breakpoint-min($tablet) {
          margin-inline-start: 10px;
        }
      }
    }
  }

  &-container {
    position: relative;
    border-radius: 4px !important;
    --scrollbar-background: var(--color-light);
    box-shadow: inset 0 0 0 1px var(--color-neutral-20);

    @include breakpoint-max($tablet) {
      padding: 16px !important;
    }

    .modal {
      &__close-btn {
        top: 24px;
        z-index: 75;
        --size: 40px;
        --icon-size: 24px;
        position: absolute;
        --border-radius: 8px;
        inset-inline-end: 24px;

        @include breakpoint-max($tablet) {
          top: 16px;
          inset-inline-end: 16px;
        }
      }

      &__back {
        width: 32px;
        height: 32px;
        line-height: 32px;
        margin: 0 8px 0 0;
      }
    }
  }

  &-content {
    @include breakpoint-max($tablet) {
      margin-left: -16px !important;
      margin-right: -16px !important;
      padding-left: 16px !important;
      padding-right: 16px !important;
      max-height: calc(100vh - 320px) !important;
    }
  }
}

.cdk-overlay-dark-backdrop {
  background-color: rgba(var(--color-black-rgb), 0.15);
}

.modal {
  @include breakpoint-max($tablet) {
    max-width: 400px !important;
    width: calc(100% - 32px) !important;
  }
}

.confirm-modal {
  @include breakpoint-max($tablet) {
    text-align: center;

    .mat-dialog {
      &-container {
        padding: 80px 24px 24px !important;

        .modal {
          &__close-btn {
            top: 24px;
            right: 24px;
          }
        }
      }
    }
  }
}
