@import 'helpers';

.tooltip-btn {
  --size: 18px;
  --icon-size: 18px;
  vertical-align: top;
  display: inline-block;
  margin-inline-start: 4px;
}

.mat-tooltip {
  font-size: 16px;
  line-height: 1.3;
  max-width: 371px !important;
  margin: 0 0 0 8px !important;
  border-radius: 4px !important;
  padding: 19px 24px !important;
  background: var(--color-light);
  color: var(--color-neutral-90) !important;
  box-shadow: 0 4px 20px rgba(var(--color-light-contrast-rgb), 0.1);

  @include breakpoint-max($mobile) {
    padding: 16px !important;
    max-width: 280px !important;
  }
}
