@import 'helpers';

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-background {
  background-color: var(--color-primary-60);
}

.mat-checkbox-checkmark {
  fill: var(--color-neutral-100);
}

.mat-pseudo-checkbox {
  margin-top: 3.5px;
  margin-inline-start: 3.5px;
  margin-bottom: 3.5px;
  margin-right: 3.5px;
  width: 21px !important;
  height: 21px !important;
  border-width: 2px !important;
  color: var(--color-neutral-80);

  &:after {
    left: 2px !important;
    top: 3.4px !important;
    border-bottom-width: 2px !important;
  }
}

.mat-pseudo-checkbox-checked {
  &:after {
    width: 12px !important;
    height: 6px !important;
    border-left-width: 2px !important;
  }

  &.mat-pseudo-checkbox-disabled {
    background: var(--color-neutral-50);
  }
}

.mat-list-base .mat-list-item.mat-list-item-with-avatar .mat-list-item-content .mat-list-text,
.mat-list-base .mat-list-item.mat-list-option .mat-list-item-content .mat-list-text,
.mat-list-base .mat-list-option.mat-list-item-with-avatar .mat-list-item-content .mat-list-text,
.mat-list-base .mat-list-option.mat-list-option .mat-list-item-content .mat-list-text {
  padding-inline-start: 11.5px !important;
}

.mat-list-base {
  .mat-list-option {
    font-size: 16px;
    line-height: 1.5;
  }

  .mat-list-item-disabled {
    background: var(--color-neutral-10);
  }
}

.mat-checkbox-checkmark-path {
  stroke: var(--color-neutral-100) !important;
}

.mat-checkbox-mixedmark {
  background-color: var(--color-neutral-100);
}

.mat-primary {
  .mat-pseudo-checkbox {
    &:after {
      border-bottom-color: var(--color-neutral-100);
    }
  }

  .mat-pseudo-checkbox-checked {
    &:after {
      border-left-color: var(--color-neutral-100);
    }
  }
}

.form-head-panel {
  display: flex;
  margin: 0 0 24px;
  align-items: center;
  justify-content: space-between;

  @include breakpoint-max($tablet) {
    margin-bottom: 16px;
  }

  h1 {
    margin-bottom: 0;
  }
}

.form-controls-panel {
  display: flex;
  margin: 0 -8px;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-end;

  @include breakpoint-max($tablet) {
    margin-left: -5px;
    margin-right: -5px;
  }

  mzima-client-button {
    margin: 0 8px;

    @include breakpoint-max($tablet) {
      margin-left: 5px;
      margin-right: 5px;
    }

    &:not(.delete-btn) {
      min-width: 125px;

      @include breakpoint-max($mobile) {
        min-width: 115px;
      }
    }
  }

  &--sticky {
    left: 0;
    right: 0;
    z-index: 50;
    bottom: -32px;
    position: sticky;
    padding: 8px 15px 23px;
    margin: -8px -23px -23px;
    border-radius: 0 0 4px 4px;
    background: var(--color-light);

    @include breakpoint-max($laptop-small) {
      bottom: -22px;
      padding: 8px 7px 15px;
      margin: -8px -15px -15px;
    }

    @include breakpoint-max($tablet) {
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      position: fixed;
      flex-wrap: nowrap;
      padding: 11px 11px 12px;
      border-top: 1px solid var(--color-neutral-20);
    }
  }
}

.form-controls-spacer {
  @include breakpoint-max($tablet) {
    height: 72px;
  }
}

.mat-form-field {
  width: 100%;
}

.mat-primary {
  .mat-option {
    &.mat-selected {
      &:not(.mat-option-disabled) {
        color: #000;
      }
    }
  }
}

.mat-select-panel {
  .mat-option {
    &.mat-selected {
      &:not(.mat-option-multiple) {
        background-color: var(--color-neutral-20);
      }
    }
  }
}

.mat-option {
  &:hover,
  &:focus {
    &:not(.mat-option-disabled) {
      background-color: var(--color-neutral-10);
    }
  }
}

.mat-form-field.mat-focused {
  .mat-form-field-label {
    color: var(--color-primary-60);
  }

  .mat-form-field-ripple {
    background-color: var(--color-primary-60);
  }

  &.mat-primary {
    .mat-select-arrow {
      color: var(--color-neutral-100);
    }
  }
}

.mat-form-field.mat-form-field-invalid {
  .mat-form-field-label {
    color: var(--color-accent);
  }

  .mat-form-field-ripple,
  .mat-form-field-ripple.mat-accent {
    background-color: var(--color-accent);
  }
}

.mat-radio-button.mat-accent.mat-radio-checked {
  .mat-radio-outer-circle {
    border-color: var(--color-primary-60);
  }
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: var(--color-primary-60);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb,
.mat-slide-toggle.mat-checked .mat-ripple-element {
  box-shadow: none;
  background-color: var(--color-light);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: var(--color-primary-60);
}

.mat-calendar-body-selected {
  color: var(--color-neutral-90);
  font-weight: 700;
  background-color: var(--color-primary-60);
}

.mat-calendar-body-in-range {
  &:before {
    background: var(--color-primary-100);
  }
}

.mat-calendar-body-cell {
  height: 40px !important;
  position: relative !important;
}

@media (hover: hover) {
  .mat-calendar-body-cell {
    &:not(.mat-calendar-body-disabled) {
      &:hover {
        > .mat-calendar-body-cell-content {
          &:not(.mat-calendar-body-selected) {
            &:not(.mat-calendar-body-comparison-identical) {
              background-color: var(--color-primary-90);
            }
          }
        }
      }
    }
  }
}

.cdk-program-focused,
.cdk-keyboard-focused {
  .mat-calendar-body-active {
    > .mat-calendar-body-cell-content {
      &:not(.mat-calendar-body-selected) {
        &:not(.mat-calendar-body-comparison-identical) {
          background-color: var(--color-primary-90);
        }
      }
    }
  }
}

.tooltip-icon {
  width: 16px;
  height: 16px;
  flex: 0 0 16px;
  font-size: 10px;
  margin-inline-start: 4px;
  line-height: 16px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  background: var(--color-neutral-100);
  color: var(--color-neutral-10);
}

.mat-list-base.mat-selection-list {
  .mat-list-option {
    height: auto;
    display: flex;
    min-height: 32px;
    align-items: center;
    justify-content: flex-start;
  }

  .mat-list-item,
  .mat-list-option {
    .mat-list-item-content {
      padding: 12px;
      flex-basis: 100%;
      min-height: 52px;

      @include breakpoint-max($tablet) {
        min-height: 48px;
        padding: 8px 16px;
      }
    }
  }
}

.mat-list-base {
  .mat-list-option {
    color: var(--color-neutral-90);
  }
}

.multilevelselect {
  .mat-tree {
    width: 602px;
    padding: 0 16px;
  }

  .mat-list-option {
    flex: 1 1 100%;
  }

  .mat-list-item-content {
    display: flex;
    min-height: 36px;
    align-items: center;
    justify-content: flex-start;
  }
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: var(--color-accent);
}

.mat-form-field.mat-form-field-invalid {
  .mat-form-field-label.mat-accent,
  .mat-form-field-label .mat-form-field-required-marker {
    color: var(--color-accent);
  }
}

.mat-form-field-required-marker {
  color: var(--color-accent);
}

.form-label,
.form-row > mat-label {
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 4px;
  color: var(--color-neutral-100);
}

.form-row {
  flex: 1 1 100%;
  margin-bottom: 24px;

  // @include breakpoint-max($tablet) {
  //   margin-bottom: 32px;
  // }

  &--small {
    > mat-label {
      font-size: 12px;
      margin-bottom: 4px;
    }

    .mat-form-field-appearance-outline {
      .mat-form-field-infix {
        font-size: 12px;
      }

      .mat-input-element {
        height: 18px;
        padding: 6px 16px;
        max-width: calc(100% - 34px);
      }
    }
  }

  &__error {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
  }

  app-group-checkbox-select {
    display: block;
    padding-top: 4px;
    margin-bottom: 12px;
  }
}

.mat-slide-toggle {
  font-size: 16px;
  min-height: 32px;
  margin-bottom: 20px;
  height: auto !important;
  color: var(--color-neutral-100);
  line-height: 1.4 !important;
}

.mat-slide-toggle-content {
  font-family: inherit;
  white-space: normal !important;
}

.mat-slide-toggle-bar {
  width: 46px !important;
  height: 28px !important;
  border-radius: 15px !important;
  margin-inline-end: 16px !important;
}

.mat-slide-toggle-thumb-container {
  top: 2px !important;
  width: 24px !important;
  right: auto !important;
  height: 24px !important;
  inset-inline-start: 2px !important;

  .mat-slide-toggle.mat-checked & {
    transform: translate3d(18px, 0, 0) !important;

    [dir='rtl'] & {
      transform: translate3d(-18px, 0, 0) !important;
    }
  }
}

.mat-slide-toggle-thumb {
  width: 100% !important;
  height: 100% !important;
}

.mat-form-field-appearance-outline {
  vertical-align: top;
  --scrollbar-background: var(--color-light);

  .mat-form-field-outline {
    top: 0 !important;
    color: var(--color-neutral-30);
  }

  &.mat-focused {
    .mat-form-field-outline-thick {
      color: var(--color-neutral-30);

      .mat-form-field-outline-end {
        border-color: var(--color-neutral-100);
      }
    }
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
    margin: 0 !important;
  }

  .mat-form-field-flex {
    vertical-align: top;
    align-items: stretch;
    margin-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .mat-form-field-infix {
    font-weight: 400;
    border-top: none;
    padding-top: 0;
    padding-bottom: 0;
  }

  .mat-form-field-outline-start,
  .mat-form-field-outline-gap {
    display: none;
  }

  .mat-form-field-outline-end {
    border-radius: 4px !important;
    border-left-style: solid !important;
    border-right-style: solid !important;
  }

  .mat-form-field-subscript-wrapper {
    top: auto;
    margin-top: 0;
    font-size: 14px;
    position: static;
    line-height: 1.3;
    padding: 0 !important;
    color: var(--color-neutral-80) !important;

    .mat-hint,
    .mat-error {
      margin-top: 8px;

      p {
        margin-bottom: 0;

        &:not(:first-child) {
          margin-top: 4px;
        }
      }
    }
  }

  .mat-input-element {
    margin: 1px;
    height: 24px;
    padding: 7px 21px;
    border-radius: 4px;
    background: transparent;
    max-width: calc(100% - 45px);
    color: var(--color-neutral-100);

    @include breakpoint-max($tablet) {
      padding: 11px 23px;
      max-width: calc(100% - 48px);
    }

    &[readonly='true'] {
      color: var(--color-neutral-70);
    }

    &::placeholder {
      opacity: 1;
      color: var(--color-neutral-70);
    }
    &:-ms-input-placeholder {
      opacity: 1;
      color: var(--color-neutral-70);
    }
    &::-ms-input-placeholder {
      opacity: 1;
      color: var(--color-neutral-70);
    }

    &:focus {
      &::placeholder {
        color: transparent;
      }
      &:-ms-input-placeholder {
        color: transparent;
      }
      &::-ms-input-placeholder {
        color: transparent;
      }
    }
  }

  .mat-select-trigger {
    margin: 1px;
    height: 38px;
    border-radius: 4px;
    width: calc(100% - 2px);
    background: transparent;

    @include breakpoint-max($tablet) {
      height: 46px;
    }
  }

  .mat-select-value {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-inline-end: 7px;
    padding-inline-start: 21px;

    @include breakpoint-max($tablet) {
      padding-top: 11px;
      padding-bottom: 11px;
      padding-inline-end: 11px;
      padding-inline-start: 24px;
    }
  }

  .mat-select-arrow-wrapper {
    transform: none !important;
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    display: flex;
    flex-shrink: 0;
    line-height: 1;
    font-size: 16px;
    top: 0 !important;
    flex-direction: column;
    justify-content: center;
  }

  .mat-form-field-prefix {
    flex-basis: 40px;
    padding-left: 8px;
    align-items: flex-end;

    + .mat-form-field-infix {
      .mat-input-element,
      .mat-select-value {
        padding-left: 10px;
      }
    }

    mzima-client-button {
      width: 100%;
      --size: 40px;
      --icon-size: 32px;
    }
  }

  .mat-form-field-suffix {
    flex-basis: 40px;
    align-items: center;

    mzima-client-button {
      width: 100%;
      --size: 40px;
      --icon-size: 24px;
    }

    .mat-datepicker-toggle-default-icon {
      width: 1em !important;
      height: 1em !important;
    }

    mat-datepicker-toggle {
      .mat-icon-button {
        background: transparent;
        border-color: transparent;
      }
    }
  }

  .mat-datepicker-toggle {
    transition: color 0.35s ease;
  }

  .mat-datepicker-toggle-active {
    color: var(--color-primary-60);
  }
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  .mat-form-field-outline-start,
  .mat-form-field-outline-end,
  .mat-form-field-outline-gap {
    border-width: 1px !important;
    transition: border-color 0.35s ease;
  }
}

.mat-calendar-body-today {
  &:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-color: transparent;
    text-decoration: underline;
  }
}

.mat-calendar-body-cell-content {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
}

.mat-calendar-body-cell::before,
.mat-calendar-body-cell::after,
.mat-calendar-body-cell-preview {
  top: 0 !important;
  height: 100% !important;
}

.mat-calendar-body-cell-container {
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  &:first-child {
    .mat-calendar-body-cell::before,
    .mat-calendar-body-cell::after,
    .mat-calendar-body-cell-preview {
      border-radius: 10px 0 0 10px;
    }
  }

  &:last-child {
    .mat-calendar-body-cell::before,
    .mat-calendar-body-cell::after,
    .mat-calendar-body-cell-preview {
      border-radius: 0 10px 10px 0;
    }
  }
}

.mat-calendar-table-header {
  tr {
    &:nth-child(2) {
      display: none;
    }
  }

  th {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    padding-bottom: 0 !important;
  }
}

.mat-autocomplete-panel {
  &:not([class*='mat-elevation-z']) {
    box-shadow: none;
  }

  .mat-option {
    height: auto;
    font-size: 16px;
    line-height: 1.6;
    font-weight: 400;
    padding: 8px 15px;
    white-space: normal;

    .mat-option-text {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.mat-radio-group {
  padding-top: 8px;

  .mat-radio-button {
    display: block;
  }

  .mat-radio-label {
    padding: 11px 16px;
  }
}

.mat-select {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--color-neutral-100);

  .mat-select-arrow-wrapper {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    //margin: 0 16px;

    @include breakpoint-max($tablet) {
      width: 32px;
    }
  }

  .mat-select-arrow {
    margin: 0;
    width: 100%;
    height: 100%;
    border: none;
    transition: transform 0.35s ease;
    background: url(/assets/images/select-arrow.svg) 50% 50% / contain no-repeat;
  }

  .mat-select-placeholder {
    color: var(--color-neutral-90);
  }

  &[aria-expanded='true'] {
    .mat-select-arrow {
      transform: rotate(180deg) !important;
    }
  }
}

.mat-select-panel-wrap {
  .mat-select-panel {
    margin-top: 44px;
    margin-inline-start: 16px;
    border-radius: 6px;
    min-width: 100% !important;
    box-shadow: none !important;
    border: 1px solid var(--color-neutral-30);

    .mat-optgroup-label,
    .mat-option {
      height: auto;
      font-size: 16px;
      min-height: 52px;
      line-height: 1.5;
    }
  }

  .mat-option {
    padding-left: 22px;
    padding-right: 22px;
  }
}

.mat-checkbox-layout {
  align-items: center !important;
  white-space: normal !important;
  word-wrap: break-word;
  overflow: hidden;
  max-width: 100%;

  @include breakpoint-max($tablet) {
    align-items: flex-start !important;
  }

  .mat-checkbox-label {
    max-width: calc(100% - 36px);
    margin-top: 1px;
  }
}

.mat-checkbox-inner-container {
  width: 21px !important;
  height: 21px !important;
  margin-top: 3.5px !important;
  margin-bottom: 3.5px !important;
  margin-inline-end: 12px !important;
  margin-inline-start: 3.5px !important;

  @include breakpoint-max($tablet) {
    margin-top: 1.5px !important;
    margin-bottom: 1.5px !important;
  }
}

.mat-checkbox-background,
.mat-checkbox-frame {
  border-radius: 3px;
}

.mat-checkbox-frame {
  border-color: var(--color-neutral-80);
}

.mat-checkbox {
  font-size: 16px;
  line-height: 1.5;
  max-width: 100%;

  + p {
    margin-top: 8px;

    @include breakpoint-max($tablet) {
      color: var(--color-neutral-90);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.checkbox-group {
  margin-top: 8px;

  .checkbox {
    &:not(:first-child) {
      @include breakpoint-max($tablet) {
        margin-top: 24px;
      }
    }
  }
}

.checkbox {
  padding: 12px;

  @include breakpoint-max($laptop-small) {
    padding: 8px;
  }

  @include breakpoint-max($tablet) {
    padding: 0;
  }
}

.toggle-group {
  .toggle {
    &:not(:first-child) {
      margin-top: 24px;
    }
  }
}

.toggle {
  font-size: 16px;
  line-height: 1.5;
  padding: 9px 16px;

  .mat-slide-toggle {
    min-height: 24px;
    margin-bottom: 0;
    vertical-align: top;
    line-height: inherit !important;
  }

  .mat-slide-toggle-bar {
    width: 42px !important;
    height: 24px !important;
    border-radius: 12px !important;
    margin-inline-end: 16px !important;
  }

  .mat-slide-toggle-thumb-container {
    width: 20px !important;
    height: 20px !important;
  }

  &__description {
    font-size: 14px;
    line-height: 1.3;
    margin-top: 8px !important;
    margin-bottom: 0 !important;
    color: var(--color-neutral-90);
  }
}

.image-loader {
  &__btn {
    margin-bottom: 16px;
  }

  .form-row {
    margin-bottom: 0;
  }
}

.auth-form {
  padding-top: 20px;

  .form-row {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .mat-form-field-wrapper {
    .mat-form-field-flex {
      padding-top: 0.15em;
    }
  }

  .mat-form-field-suffix {
    align-self: center;
  }

  .mat-form-field-label {
    color: var(--color-black);
  }

  .mat-form-field-invalid {
    caret-color: var(--color-accent);
    .mat-form-field-label {
      color: var(--color-accent);
    }
  }

  .mat-form-field-required-marker {
    color: var(--color-black);
  }

  .mat-focused {
    .mat-form-field-required-marker {
      color: var(--color-primary-60);
    }
  }
}
