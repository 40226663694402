@import 'helpers';

.mat-table {
  width: 100%;
  font-size: 14px;
  line-height: 1.3;
  color: var(--color-black);

  th.mat-header-cell,
  td.mat-cell,
  td.mat-footer-cell {
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 32px;
    padding-right: 32px;

    @include breakpoint-max($laptop-small) {
      padding-left: 16px;
      padding-right: 16px;
    }

    &:first-of-type {
      padding-left: 32px;

      @include breakpoint-max($laptop-small) {
        padding-left: 16px;
      }
    }

    &:last-of-type {
      padding-right: 32px;

      @include breakpoint-max($laptop-small) {
        padding-right: 16px;
      }
    }
  }

  mat-row,
  mat-header-row,
  mat-footer-row,
  th.mat-header-cell,
  td.mat-cell,
  td.mat-footer-cell {
    border-bottom-color: var(--color-neutral-20);
  }

  td.mat-cell {
    @include breakpoint-max($laptop-small) {
      font-size: 12px;
    }
  }

  .mat-header-row {
    height: 37px;
    background: var(--color-neutral-10);
  }

  tr.mat-row,
  tr.mat-footer-row {
    height: 38px;
  }

  .mat-header-cell {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.5;
    border-bottom: none;
    color: var(--color-black);
  }

  .title {
    font-size: 16px;

    @include breakpoint-max($laptop-small) {
      font-size: 14px;
    }
  }
}
