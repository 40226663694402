@import 'helpers';

.mat-paginator-range-actions {
  .mat-button-base {
    &:not(.mat-button-disabled) {
      &:hover {
        color: var(--color-primary-60);
      }
    }
  }
}

.pagination {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.6;
  margin-top: 32px;
  text-align: center;

  .ngx-pagination {
    display: flex;
    margin: 0 -4px;
    align-items: center;
    justify-content: center;

    @include breakpoint-max($mobile) {
      margin-left: -2px;
      margin-right: -2px;
    }

    > li {
      padding: 0;
      margin: 0 4px;

      @include breakpoint-max($mobile) {
        margin-left: 2px;
        margin-right: 2px;
      }

      > a,
      > span {
        min-width: 32px;
        min-height: 32px;
        padding: 5px 4px;
        border-radius: 4px;
        vertical-align: top;
        text-decoration: none;
        display: inline-block;
        color: var(--color-dark);
        transition: all 0.35s ease;
        background: var(--color-light);
        box-shadow: inset 0 0 0 1px var(--color-neutral-20);
      }

      > a {
        &:hover {
          background-color: var(--color-primary-90);
          box-shadow: inset 0 0 0 1px var(--color-neutral-30);
        }
      }

      &.current {
        color: inherit;
        background: none;

        > span {
          box-shadow: none;
          background: var(--color-primary-60);
        }
      }

      &.pagination-next,
      &.pagination-previous {
        &:before,
        &:after {
          display: none;
        }

        > a,
        > span {
          text-indent: -9999px;
          background-size: 24px 24px;
          background-repeat: no-repeat;
          background-position: 50% 50%;

          &:before,
          &:after {
            display: none;
          }
        }
      }

      &.pagination-previous {
        > a,
        > span {
          background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.41 7.41L10.83 12L15.41 16.59L14 18L8.00003 12L14 6L15.41 7.41Z' fill='%23181B21'/%3E%3C/svg%3E%0A");
        }

        &.disabled {
          > a,
          > span {
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.41 7.41L10.83 12L15.41 16.59L14 18L8.00003 12L14 6L15.41 7.41Z' fill='%23D3D4D5'/%3E%3C/svg%3E%0A");
          }
        }
      }

      &.pagination-next {
        > a,
        > span {
          background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 16.59L12.58 12L8 7.41L9.41 6L15.41 12L9.41 18L8 16.59Z' fill='%23181B21'/%3E%3C/svg%3E%0A");
        }

        &.disabled {
          > a,
          > span {
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 16.59L12.58 12L8 7.41L9.41 6L15.41 12L9.41 18L8 16.59Z' fill='%23D3D4D5'/%3E%3C/svg%3E%0A");
          }
        }
      }
    }
  }
}
