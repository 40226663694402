@import 'helpers';

.mat-tab-header {
  margin-bottom: 24px;
  border-bottom: none;

  .mat-ink-bar {
    height: 3px;
    border-radius: 2px;
  }
}

.mat-tab-labels {
  padding-bottom: 3px;
  justify-content: flex-start;

  .mat-tab-label {
    opacity: 1;
    height: 40px;
    font-size: 20px;
    line-height: 1.2;
    min-width: 140px;
    font-weight: 500;
    background: none !important;
    transition: color 0.35s ease;
    color: var(--color-neutral-60);

    @include breakpoint-max($laptop-small) {
      @include breakpoint-min($tablet) {
        font-size: 18px;
        min-width: 128px;
      }
    }

    @include breakpoint-max($mobile) {
      min-width: 94px;
      padding-left: 8px;
      padding-right: 8px;

      &:not(:first-child) {
        margin-inline-start: 8px;
      }

      &:not(:last-child) {
        margin-inline-end: 8px;
      }
    }

    &.mat-tab-disabled {
      color: var(--color-neutral-50);
    }

    &:not(.mat-tab-disabled) {
      &:hover,
      &.mat-tab-label-active {
        color: var(--color-black);
      }
    }
  }
}
