.mat-progress-bar-background {
  fill: transparent;
}

.mat-progress-bar-buffer {
  background-color: transparent;
}

.mat-progress-bar {
  min-height: 5px;
  background: var(--color-light);
}

.mat-progress-bar-fill {
  &:after {
    border-radius: 0 3px 3px 0;
    background-color: var(--color-primary-60);
  }
}
