.cdk-overlay-container {
  .mat-menu-panel {
    margin-top: 4px;
    max-width: none;
    box-shadow: none;
    max-height: 400px;
    border-radius: 4px;
    box-shadow: inset 0 0 0 1px var(--color-neutral-60);
  }
}

.mat-menu-panel {
  padding-left: 1px;
  padding-right: 1px;

  .mat-menu-item {
    height: auto;
    font-size: 16px;
    min-height: 48px;
    line-height: 1.5;
    padding: 8px 16px;

    &.active {
      background-color: var(--color-primary-100);
    }

    .mat-icon {
      width: 1em;
      height: 1em;
      font-size: 24px;
      margin-inline-end: 8px !important;
      margin-inline-start: -8px !important;
    }
  }

  hr {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
