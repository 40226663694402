@use '@angular/material' as mat;

$ushahidi-primary-palette: (
  300: var(--color-primary-70),
  400: var(--color-primary-60),
  500: var(--color-primary-50),
  contrast: (
    300: var(--color-neutral-100),
    400: var(--color-neutral-100),
    500: var(--color-neutral-100),
  ),
);

$ushahidi-acccent-palette: (
  400: var(--color-neutral-50),
  500: var(--color-neutral-40),
  600: var(--color-neutral-30),
  contrast: (
    400: var(--color-neutral-10),
    500: var(--color-neutral-100),
    600: var(--color-neutral-100),
  ),
);

$ushahidi-warn-palette: (
  400: var(--color-accent-shade),
  500: var(--color-accent),
  600: var(--color-accent-tint),
  contrast: (
    400: var(--color-accent-contrast),
    500: var(--color-accent-contrast),
    600: var(--color-accent-contrast),
  ),
);

$ushahidi-primary: mat.define-palette($ushahidi-primary-palette, 400, 300, 500);
$ushahidi-acccent: mat.define-palette($ushahidi-acccent-palette, 500, 400, 500);
$ushahidi-warn: mat.define-palette($ushahidi-warn-palette, 500, 400, 500);

$ushahidi-typography: mat.define-typography-config(
  $font-family: var(--font-family),
  $body-1: mat.define-typography-level(14px, 1.3, 400),
  $subheading-1: mat.define-typography-level(24px, 1.5, 500),
  $subheading-2: mat.define-typography-level(20px, 1.28, 500),
  $button: mat.define-typography-level(16px, 40px, 500),
  $input: mat.define-typography-level(16px, 1.5, 400),
);

$ushahidi-theme: mat.define-light-theme(
  (
    color: (
      primary: $ushahidi-primary,
      accent: $ushahidi-acccent,
      warn: $ushahidi-warn,
    ),
    typography: $ushahidi-typography,
  )
);

@include mat.all-component-themes($ushahidi-theme);
@include mat.core($ushahidi-theme);

.mat-typography {
  h1 {
    margin-bottom: 8px;
  }
}
