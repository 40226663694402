.color {
  &-primary {
    color: var(--color-primary-60);
  }
  &-accent {
    color: var(--color-accent);
  }
  &-dark {
    color: var(--color-neutral-100);
  }
}
